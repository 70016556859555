import { RouteComponent } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { map, take } from "lodash";
import { FC, PropsWithChildren } from "react";
// import { ErrorComponent } from "~/components/error";
import { IndexLoaderResult } from "./index.server";
// import { Search } from "~/components/search";

// export { headers } from "./index.server";

const images = [
	require("assets/media/images/1680447799755.webp"),
	// require("assets/media/images/1680448086885.webp"),
	// require("assets/media/images/1680503530424.webp"),
	require("assets/media/images/IMG_1981.webp"),
	require("assets/media/images/1685262618610.webp"),
	// require("assets/media/images/IMG_2033.webp"),
	// require("assets/media/images/IMG_2035.webp"),
	require("assets/media/images/blackout edit 2.webp"),
	require("assets/media/images/1685262368659.webp"),
	// require("assets/media/images/Euphoria maina edit.webp"),
	require("assets/media/images/1685262617585.webp"),
	require("assets/media/images/1685262617491.webp"),
	require("assets/media/images/1685262618696.webp"),
	// require("assets/media/images/1685262617817.webp"),
	// require("assets/media/images/1685262618310.webp"),
	// require("assets/media/images/1685262618510.webp"),
];

const imageHolders = 10;

const IndexComponent: FC<PropsWithChildren<{}>> = ({ children }) => {
	return (
		<div className="relative flex flex-col w-full h-full pt-40 bg-black overflow-x-hidden overflow-y-hidden max-w-[100vw] justify-center">
			<div className="relative flex h-full items-center justify-center">
				<div
					className={
						"flex h-full overflow-x-auto snap-x snap-mandatory"
					}
				>
					{map(take(images, 10), (image, i) => {
						const imageURI = encodeURI(image);
						return (
							<div
								key={i}
								className="flex mx-1 items-center justify-center w-screen h-full shrink-0 snap-center"
							>
								<img
									className="h-full overflow-hidden object-cover aspect-[2/3] w-full bg-black"
									src={imageURI}
									loading={i > 2 ? "lazy" : undefined}
									srcSet={`/res${imageURI}?w=600px 600w, /res${imageURI}?w=800px 800w, /res${imageURI}?w=900px 900w, /res${imageURI}?w=1200px 1200w, /res${imageURI}?w=1600px 1600w`}
									sizes="100vw"
								/>
							</div>
						);
					})}
				</div>
				<div className="absolute top-4 w-full px-4 space-y-2 pt-4 mix-blend-exclusion text-white">
					{/* <div className="text-5xl font-bold">Creating</div> */}
					{/* <div className="text-5xl font-bold">Unforgettable</div> */}
					{/* <div className="text-5xl font-bold ">Moments!</div> */}
					<div className="text-5xl font-bold ">Expressing</div>
					<div className="text-5xl font-bold ">Culture!</div>
				</div>
			</div>
		</div>
	);
};

const index: RouteComponent = () => {
	const loaderData = useLoaderData<IndexLoaderResult>();
	return <IndexComponent />;
};

export default index;
